import React, { useState } from "react";
import { Helmet } from 'react-helmet-async';

const FORM_ENDPOINT = "https://public.herotofu.com/v1/e9c5a050-7fc2-11ee-9341-f71d382974be";

const Contact = (props) => {

  const [submitted, setSubmitted] = useState(false);


  const handleSubmit = (e) => {  
    e.preventDefault();

    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    fetch(FORM_ENDPOINT, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Form response was not ok');
      }
        setSubmitted(true);
    })
    .catch((err) => {
      // Submit the form manually
      e.target.submit();
    });
  };

  return (
    <main id="main">
      <Helmet>
        <title>Contacy Us | PHP-MVC Framework with login manager</title>
        <meta name="description" content="Easy to use and light weight MVC framework for PHP." />
        <meta name="keywords" content="mvclm, mvc, php, mvc framework, php framework, contact" />
      </Helmet>

      <section id="form" className="contact">
        <div className="container">
            <div className="section-title">
                <p className="contact-header">Contact Us</p>
            </div>

            <div className="row">
                <div className="col-lg-12 mt-0">

                  { (submitted || props.message === "sent") ?                     
                    <div className="mb-3">
                    <div className="message-sent">Your message has been sent. Thank you!</div>
                    </div>
                  : 
                    <form action={FORM_ENDPOINT} onSubmit={handleSubmit} method="POST">
                          <div className="form-row">
                              <div className="col-md-6 form-group">
                                  <input type="text" placeholder="Your name" name="name" className="form-control" required />
                              </div>

                              <div className="col-md-6 form-group">
                                  <input type="email" placeholder="Your Email" name="email" className="form-control" required />
                              </div>
                          </div>

                          <div className="form-group">
                              <textarea id="message" placeholder="Your message" name="message" className="form-control" rows="5" required></textarea>
                          </div>

                          <div className="text-center">
                              <button type="submit" className="btn-get-started mt-3">Send Message</button>
                          </div>
                      </form>
                    }

                </div>
            </div>

          </div>
      </section>
    </main>
  );
};

export default Contact;
