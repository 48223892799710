import React from "react";
import { Helmet } from "react-helmet-async";

function Privacy() {
  return (
    <section className="inner-page">
      <Helmet>
        <title>Privacy | PHP-MVC Framework with login manager</title>
        <meta name="description" content="Easy to use and light weight MVC framework for PHP." />
        <meta name="keywords" content="mvclm, mvc, php, mvc framework, php framework, privacy" />
      </Helmet>

      <div className="container">
        <article className="box post legal-article">
          <header>
            <h3>PRIVACY POLICY</h3>
          </header>

          <p className="terms-paragraph">
            This privacy policy (hereinafter referred to as "Privacy Policy" or
            "Policy") governs the manner in which MvcLM.com website (hereinafter
            referred to as "Website", "We", "Us" or "Our") collects, uses,
            maintains and discloses information collected from users at Website.
          </p>

          <p className="terms-paragraph">
            YOUR USE OF THE WEBSITE SIGNIFIES THAT YOU HAVE READ, UNDERSTAND AND
            AGREE TO THIS PRIVACY POLICY. By accessing or using the Website you
            also confirm that you have the legal authority to accept this
            Privacy Policy on behalf of yourself and any party you represent in
            connection with your use of the Website. If you do not agree to
            Privacy Policy, you are not authorized to use the Website.
          </p>

          <p className="terms-header">1. Principles</p>
          <p className="terms-paragraph">
            <b>1.1</b> We respect your privacy regarding any information We
            collect.
            <br />
            <b>1.2</b> We ask personally-identifying information only if we
            truly need it.
            <br />
            <b>1.3</b> We will not rent or sell personally-identifying
            information to anyone.
            <br />
          </p>

          <p className="terms-header">2. Website Visitors</p>
          <p className="terms-paragraph">
            Like most website operators, We may collect
            non-personally-identifying information of the sort that web browsers
            and servers typically make available, such as the browser type,
            language preference, referring site, and the date and time of each
            visitor request. Our purpose in collecting non-personally
            identifying information is to better understand how visitors use the
            Website. From time to time We may release non-personally-identifying
            aggregated information, for example, by publishing a report on
            trends about the usage of the Website.
          </p>

          <p className="terms-header">3. Cookies</p>
          <p className="terms-paragraph">
            Cookie is a string of information that website stores on a visitor's
            computer, and that the visitor's browser provides to the website
            each time the visitor returns. We may use cookies to improve the
            Website. Visitors who do not wish to have cookies placed on their
            computers should set their browsers to refuse cookies before using
            the Website, with the drawback that certain features may not
            function properly without the aid of cookies.
          </p>

          <p className="terms-header">4. Business Transfers</p>
          <p className="terms-paragraph">
            If Us, or the Website were acquired, user information would be one
            of the assets that is transferred or acquired by a third party. You
            acknowledge that such transfers may occur, and that any acquirer of
            Us or the Website may continue to use your personal information as
            set forth in this Privacy Policy.
          </p>

          <p className="terms-header">5. Third-Party Content</p>
          <p className="terms-paragraph">
            Third-party content appearing on the Website may be delivered to
            users by our partners, who may set cookies. These cookies allow the
            partner to recognize your computer each time you interact with the
            content to compile information about you or others who use your
            computer. This Privacy Policy covers the use of cookies by Us and
            does not cover the use of cookies by any partners.
          </p>

          <p className="terms-header">6. Third-Party Websites</p>
          <p className="terms-paragraph">
            Website may include links to other websites. You should consult the
            respective privacy policies of these third-party sites. This Privacy
            Policy does not apply to, and we cannot control the activities of,
            such other websites.
          </p>

          <p className="terms-header">7. Childrens Privacy</p>
          <p className="terms-paragraph">
            Protecting the privacy of children is especially important. For that
            reason and consistent with the federal Children’s Online Privacy
            Protection Act of 1998 (COPPA), We do not knowingly collect
            Personally Identifiable Information submitted by persons under 18
            years of age. If You are under 18 years of age, then please do not
            submit any information to the Website at any time or in any manner.
          </p>

          <p className="terms-header">8. Privacy Policy Changes</p>
          <p className="terms-paragraph">
            We reserve the right to change the Privacy Policy at our sole
            discretion. In that case, We will post the updated or new Privacy
            Policy here <a href="/privacy">MvcLM.com/privacy</a>. We encourage
            you to periodically review this Privacy Policy to stay informed
            about how We are protecting the personal information We collect.
            Your continued use of the Website after updated or new Privacy
            Policy are effective indicates that you have read, understood and
            agreed to new or updated Privacy Policy.
          </p>
        </article>
      </div>
    </section>
  );
}

export default Privacy;
