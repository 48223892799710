import React from "react";
import { Helmet } from "react-helmet-async";

function Home() {
  return (
    <>
      <section id="hero" className="mb-3" style={{ marginTop: -80 }}>
        <Helmet>
          <title>MvcLM | PHP-MVC Framework with login manager</title>
          <meta name="description" content="Easy to use and light weight MVC framework for PHP." />
          <meta name="keywords" content="mvclm, mvc, php, mvc framework, php framework" />
          <link rel="canonical" href="/" />
        </Helmet>

        <div className="container">
          <div className="row">
            <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
              <div>
                <h1><span>MVC Framework</span></h1>
                <h2>MvcLM is easy to use and light weight PHP-MVC framework with login manager</h2>
                <div className="text-center text-lg-left">
                  <a href="https://github.com/jaanus-saarnak/mvclm" className="btn-get-started mr-3" rel="noopener">GitHub</a>
                  <a href="https://www.mvclm.com/demo" className="btn-get-started" rel="noopener">Demo</a>
                </div>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2 hero-img">
              <img src="" className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>

        <svg
          className="hero-waves" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28 " preserveAspectRatio="none"
        >
          <defs>
            <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="wave1">
            <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
          </g>
          <g className="wave2">
            <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
          </g>
          <g className="wave3">
            <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
          </g>
        </svg>
      </section>

      <main id="main">
        <section id="features" className="features">
          <div className="container">
            <div className="section-title">
              <h2>Features</h2>
            </div>

            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="icon-box">
                  <i className="ri-bar-chart-box-line" style={{ color: "#5578ff" }}></i>
                  <h3>Routing engine</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
                <div className="icon-box">
                  <i className="ri-bank-line" style={{ color: "#EB2020" }}></i>
                  <h3>Token based authentication</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
                <div className="icon-box">
                  <i className="ri-calendar-todo-line" style={{ color: "#e80368" }}></i>
                  <h3>SQL injection Protection</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4 mt-lg-0">
                <div className="icon-box">
                  <i className="ri-paint-brush-line" style={{ color: "#e361ff" }}></i>
                  <h3>Password guessing protection</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="ri-database-2-line" style={{ color: "#47aeff" }}></i>
                  <h3>Ajax username availability check</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="ri-gradienter-line" style={{ color: "#ffa76e" }}></i>
                  <h3>Error management</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="ri-file-list-3-line" style={{ color: "#11dbcf" }}></i>
                  <h3>Page links</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="ri-price-tag-2-line" style={{ color: "#4233ff" }}></i>
                  <h3>Flash messages</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="ri-anchor-line" style={{ color: "#b2904f" }}></i>
                  <h3>Multiple account detection</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="ri-disc-line" style={{ color: "#b20969" }}></i>
                  <h3>Add, update, delete user profiles</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="ri-base-station-line" style={{ color: "#ff5828" }}></i>
                  <h3>Admin account for user management</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="ri-fingerprint-line" style={{ color: "#29cc61" }}></i>
                  <h3>Search user profiles</h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="team" className="team">
          <div className="container">
            <div className="section-title">
              <h2>Used Technology</h2>
            </div>

            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="member">
                  <div className="pic">
                    <a href="https://en.wikipedia.org/wiki/PHP" target="_blank" rel="noreferrer">
                      <img src="assets/img/technologies/php.png" className="img-fluid" alt="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="member">
                  <div className="pic">
                    <a href="https://en.wikipedia.org/wiki/MySQLi" target="_blank" rel="noreferrer">
                      <img src="assets/img/technologies/mysqli.png" className="img-fluid" alt="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="member">
                  <div className="pic">
                    <a href="https://en.wikipedia.org/wiki/Composer_(software)" target="_blank" rel="noreferrer">
                      <img src="assets/img/technologies/composer.png" className="img-fluid" alt="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="member">
                  <div className="pic">
                    <a href="https://en.wikipedia.org/wiki/Bootstrap_(front-end_framework)" target="_blank" rel="noreferrer">
                      <img src="assets/img/technologies/bootstrap.png" className="img-fluid" alt="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="member">
                  <div className="pic">
                    <a href="https://en.wikipedia.org/wiki/JQuery" target="_blank" rel="noreferrer">
                      <img src="assets/img/technologies/jquery.png" className="img-fluid" alt="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="member">
                  <div className="pic">
                    <a href="https://en.wikipedia.org/wiki/Ajax_(programming)" target="_blank" rel="noreferrer">
                      <img src="assets/img/technologies/ajax.png" className="img-fluid" alt="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="member">
                  <div className="pic">
                    <a href="https://en.wikipedia.org/wiki/CSS" target="_blank" rel="noreferrer">
                      <img src="assets/img/technologies/html5.png" className="img-fluid" alt="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="member">
                  <div className="pic">
                    <a href="https://en.wikipedia.org/wiki/CSS" target="_blank" rel="noreferrer">
                      <img src="assets/img/technologies/css3.png" className="img-fluid" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Home;
