import React from "react";

function Footer() {
  return (
    <footer id="footer">
      <div className="container">
        <div className="copyright">
          MvcLM.com - Made with React
          <a className="mx-3" href="/terms">Terms</a>
          <a href="/privacy">Privacy</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
