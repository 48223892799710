import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Footer from "./components/Footer";

function App() {
  const location = useLocation();

  return (
    <HelmetProvider>
      <Navbar page={location.pathname} />
      <Routes>
        <Route path="/contact" element={<Contact />} />
        <Route path="/message-received" element={<Contact message="sent" />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </HelmetProvider>
  );
}

export default App;
