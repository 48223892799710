import React from "react";
import { Helmet } from "react-helmet-async";

function Terms() {
  return (
    <section className="inner-page">
      <Helmet>
        <title>Terms | PHP-MVC Framework with login manager</title>
        <meta name="description" content="Easy to use and light weight MVC framework for PHP." />
        <meta name="keywords" content="mvclm, mvc, php, mvc framework, php framework, terms" />
      </Helmet>

      <div className="container">
        <article className="box post legal-article">
          <header>
            <h3>TERMS</h3>
          </header>

          <p className="terms-paragraph">
            The following terms (hereinafter referred to as "Terms") govern all
            use of the MvcLM.com website (hereinafter referred to as "Website",
            "We", "Us" or "Our") and all content, services and products
            available at or through the Website.
          </p>
          <p className="terms-paragraph">
            YOUR USE OF THE WEBSITE SIGNIFIES THAT YOU HAVE READ, UNDERSTAND AND
            AGREE TO BE BOUND BY THESE TERMS. By accessing or using the Website
            you also confirm that you have the legal authority to accept these
            Terms on behalf of yourself and any party you represent in
            connection with your use of the Website. If you do not agree to
            these Terms, you are not authorized to use the Website.
          </p>

          <p className="terms-header">1. DISCLAIMER</p>
          <p className="terms-paragraph">
            THIS WEBSITE AND ALL CONTENT, SERVICES AND PRODUCTS AVAILABLE AT OR
            THROUGH THE WEBSITE ARE PROVIDED "AS IS", WITHOUT WARRANTY OF ANY
            KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS
            BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN
            ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN
            CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE
            SOFTWARE.
          </p>

          <p className="terms-header">2. Privacy Policy</p>
          <p className="terms-paragraph">
            We are committed to handling responsibly the information and data We
            collect through the Website and agree to use your personal
            information in accordance with the{" "}
            <a href="https://www.mvclm.com/privacy">Privacy Policy</a> and these
            Terms. The Privacy Policy is hereby incorporated into these Terms.
          </p>

          <p className="terms-header">3. Third-Party Websites</p>
          <p className="terms-paragraph">
            Our Website may include links to other websites. You should consult
            the respective terms of these third-party websites. This Terms do
            not apply to, and we cannot control the activities of, such other
            websites.
          </p>

          <p className="terms-header">4. Governing Law</p>
          <p className="terms-paragraph">
            The laws of Estonia shall govern these Terms, regardless of your
            location. You hereby expressly consent to exclusive jurisdiction of
            Estonia, for all matters or disputes arising out of or relating to
            these Terms or your access or use of the Website.
          </p>

          <p className="terms-header">5. Changes to these Terms</p>
          <p className="terms-paragraph">
            We reserve the right to change the Terms at Our sole discretion. In
            that case, We will post the updated or new Terms here 
            <a href="https://www.mvclm.com/terms">
              https://www.mvclm.com/terms
            </a>.
            We encourage you to periodically review the Terms. Your continued
            use of the Website after updated or new Terms are effective
            indicates that you have read, understood and agreed to those Terms.
          </p>
        </article>
      </div>
    </section>
  );
}

export default Terms;
